import * as React from "react"
import Layout from "../components/layout"


// markup
const KalenderPage = () => {

  return (
    <Layout pageTitle={'Kalender'}>
        <div class="px-2 md:px-0 py-4">
          <iframe src="https://calendar.google.com/calendar/embed?src=e0td9dd2enn49gdr2qvcc2jmjg%40group.calendar.google.com&ctz=Europe/Brussels height=600&amp;wkst=2&amp;bgcolor=%23ff0000&amp;ctz=Europe%2FBrussels" width="100%" height="600" frameborder="0" scrolling="no"></iframe>
        </div>
    </Layout>
  )
}

export default KalenderPage
